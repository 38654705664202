.death-scene {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    color: red;
  }
  
  .death-scene h1 {
    font-size: 4rem;
    margin-bottom: 20px;
  }
  
  .death-scene button {
    font-size: 1.5rem;
    padding: 10px 20px;
    background-color: transparent;
    border: 2px solid red;
    color: red;
    cursor: pointer;
  }
  
  .death-scene button:hover {
    background-color: red;
    color: black;
  }
  