/* src/ElvenHunterStory.css */

/* Root container for the game */
body, html, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden; /* Prevent scrolling */
  font-family: 'Arial', sans-serif; /* Optional: Set a default font family */
}

/* Game canvas container with a fixed aspect ratio of 16:9 */
.game-canvas-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  overflow: hidden;
}

/* Game canvas that scales while maintaining a 16:9 aspect ratio */
.game-canvas {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: calc(100vh * 16 / 9);
  max-height: calc(100vw * 9 / 16);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* Dialogue container styling */
.dialogue-container {
  position: absolute;
  bottom: 10%;
  right: 5%;
  width: 40%;
  background-color: rgba(0, 100, 0, 0.75);
  color: #ffffff;
  padding: 1.25em;
  border-radius: 10px;
  z-index: 10;
  text-align: left; /* Align text to the left */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Optional: Add a shadow for depth */
}

/* Text within the dialogue container */
.dialogue-text {
  font-size: 1.2em;
  margin-bottom: 0.625em;
}

/* Choices container for the player */
.choices-container button {
  display: block;
  width: 100%;
  margin-bottom: 0.625em;
  padding: 0.625em;
  background-color: #444;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1em;
  border-radius: 5px; /* Optional: Add rounded corners */
  transition: background-color 0.3s ease; /* Smooth transition on hover */
}

.choices-container button:hover {
  background-color: #555;
}

/* Close-up image styling */
.response-image {
  position: absolute;
  bottom: 0;
  left: 0;
  max-height: 75%;
  max-width: 75%;
  z-index: 10;
}

/* Full-body character image styling */
.full-body {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-height: 80%;
  max-width: 80%;
  z-index: 5;
}

/* Replay button styling */
.replay-button {
  position: absolute;
  top: 1.25em;
  right: 1.25em;
  padding: 0.625em 1.25em;
  background-color: #333;
  color: white;
  border: none;
  cursor: pointer;
  z-index: 10;
  border-radius: 5px; /* Optional: Add rounded corners */
  transition: background-color 0.3s ease; /* Smooth transition on hover */
}

.replay-button:hover {
  background-color: #555;
}

/* Next button styling inside the dialogue container */
.next-button {
  display: block; /* Ensure the button takes the full width */
  margin-left: auto; /* Push the button to the right */
  margin-top: 10px; /* Optional: Add some space between the text and the button */
  padding: 10px 20px;
  background-color: #333;
  color: white;
  border: none;
  cursor: pointer;
  text-align: right; /* Align text inside the button to the right */
  border-radius: 5px; /* Optional: Add rounded corners */
  transition: background-color 0.3s ease; /* Smooth transition on hover */
} 

.next-button:hover {
  background-color: #555;
}

/* Adjustments for mobile view */
@media (max-width: 900px) {
  .dialogue-container {
    width: 60%; /* Increase width for mobile */
    padding: 0.5em; /* Reduce padding */
    font-size: 0.875em; /* Decrease font size */
  }

  .dialogue-text {
    font-size: 0.875em; /* Further decrease font size if necessary */
  }

  .choices-container button {
    padding: 0.5em; /* Smaller padding for buttons */
    font-size: 0.875em; /* Smaller font size */
  }

  .next-button {
    padding: 0.5em 1em; /* Adjust button size */
    font-size: 0.875em;
  }
}
.battle-pose-container {
  position: absolute;
  top: 50%; /* Center vertically within the game canvas */
  left: 50%;
  transform: translate(-50%, -50%); /* Center horizontally */
  width: 100%; /* Ensure the container spans the full width of the game canvas */
  height: 100%; /* Ensure the container spans the full height of the game canvas */
  overflow: hidden; /* Crop the image within the container */
}

.battle-pose-image {
  position: absolute;
  top: 50%; /* Adjust position to center vertically */
  left: 50%;
  transform: translate(-50%, -50%) scale(1.2); /* Center and slightly scale the image */
  width: auto; /* Maintain aspect ratio */
  height: 100%; /* Ensure the image height fills the container */
  object-fit: cover; /* Ensure the image covers the entire container */
}

.pass-pose-container {
  position: absolute;
  top: 50%; /* Center vertically within the game canvas */
  left: 50%;
  transform: translate(-50%, -50%); /* Center horizontally */
  width: 100%; /* Ensure the container spans the full width of the game canvas */
  height: 100%; /* Ensure the container spans the full height of the game canvas */
  overflow: hidden; /* Crop the image within the container */
}

.pass-pose-image {
  position: absolute;
  top: 55%; /* Adjust position to center vertically */
  left: 38%;
  transform: translate(-50%, -50%) scale(1.2); /* Center and slightly scale the image */
  width: auto; /* Maintain aspect ratio */
  height: 85%; /* Ensure the image height fills the container */
  object-fit: cover; /* Ensure the image covers the entire container */
}

.chase-pose-container {
  position: absolute;
  top: 50%; /* Center vertically within the game canvas */
  left: 50%;
  transform: translate(-40%, -50%); /* Center horizontally */
  width: 100%; /* Ensure the container spans the full width of the game canvas */
  height: 100%; /* Ensure the container spans the full height of the game canvas */
  overflow: hidden; /* Crop the image within the container */
}

.chase-pose-image {
  position: absolute;
  top: 55%; /* Adjust position to center vertically */
  left: 38%;
  transform: translate(-50%, -50%) scale(1.2); /* Center and slightly scale the image */
  width: auto; /* Maintain aspect ratio */
  height: 90%; /* Ensure the image height fills the container */
  object-fit: cover; /* Ensure the image covers the entire container */
}

.elven-jumping-battle-pose-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 50%;
  overflow: hidden;
}

.elven-jumping-battle-pose {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.2);
  width: auto;
  height: 50%;
  object-fit: cover;
  animation: jump 1s ease-in-out infinite;
}

@keyframes jump {
  0%, 100% {
    transform: translate(-50%, -50%) scale(1.2);
  }
  50% {
    transform: translate(-50%, calc(-50% - 10px)) scale(1.2);
  }
}
